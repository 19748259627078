import React from "react";

const Services = ({ classicHeader, darkTheme }) => {
  // services details
  const services = [
    {
      name: "Business Analyst",
      desc: "As a capable business analyst, I possess the skills to analyze complex data, derive actionable insights, and drive strategic decision-making to propel business success.",
      icon: "fa-solid fa-business-time",
    },
    {
      name: "Consultant",
      desc: "As a skilled consultant, I offer strategic insights and innovative solutions tailored to each client's unique needs, driving measurable results and fostering long-term success in dynamic business environments.",
      icon: "fa-solid fa-user-tie",
    },
    {
      name: "Digital Marketing",
      desc: "I harness a comprehensive understanding of online platforms and consumer behavior to craft targeted campaigns that maximize brand visibility, engagement, and ultimately, drive impactful business outcomes.",
      icon: "fa-solid fa-globe",
    },
    {
      name: "Data Analytics",
      desc: "I excel in extracting valuable insights from complex datasets, enabling data-driven decision-making and facilitating strategic initiatives that optimize performance and drive business growth.",
      icon: "fa-solid fa-database",
    },
    {
      name: "Salesforce Certified",
      desc: "Proficient in Terraform, I've successfully orchestrated infrastructure at scale in challenging projects, demonstrating expertise in automating deployment and managing cloud resources efficiently to solve critical problems.",
      icon: "fa-brands fa-salesforce",
    },
  ];

  return (
    <section
      id="services"
      className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-white-50  opacity-1" : "text-light  opacity-4")
            }
          >
            Services
          </h2>
          <p
            className={
              "text-9  fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            What I Do?
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        {/* content start */}
        <div className="row">
          <div className="col-lg-11 mx-auto">
            <div className="row">
              {services.length > 0 &&
                services.map((service, index) => (
                  <div className="col-md-6" key={index}>
                    <div className="featured-box style-3 mb-5">
                      <div
                        className={
                          "featured-box-icon text-primary  shadow-sm rounded " +
                          (darkTheme ? "bg-dark-1" : "bg-white")
                        }
                      >
                        <i className={service.icon} />
                      </div>
                      <h3 className={darkTheme ? "text-white" : ""}>
                        {service.name}
                      </h3>
                      <p
                        className={"mb-0 " + (darkTheme ? "text-white-50" : "")}
                      >
                        {service.desc}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* content end */}
      </div>
    </section>
  );
};

export default Services;
