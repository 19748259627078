import React from "react";
import resumeFile from "../documents/resume.pdf";

const Resume = ({ classicHeader, darkTheme }) => {
  const experienceDetails = [
    {
      yearRange: "Aug 2022 - Present",
      title: "Analyst",
      place: "O&O Beauty · Freelance",
      desc: [
        "Analyzing datasets to derive insights and support decision-making.",
        "Generating and presenting reports on key performance indicators and metrics.",
        "Identifying and implementing improvements to enhance efficiency and effectiveness",
        "Collaborated with cross-functional teams to address performance gaps and implement corrective actions",
        "Generated regular reports to track key performance indicators (KPIs) and metrics.",
      ],
    },
    {
      yearRange: "Feb 2022 - Nov 2023",
      title: "Business Analyst",
      place: "CData",
      desc: [
        "Collaborating with stakeholders to elicit, analyze, and document business requirements for projects and initiatives.",
        "Process Mapping and Improvement: Analyzing existing business processes, identifying areas for improvement, and recommending solutions to enhance efficiency and effectiveness.",
        "Facilitating communication between business stakeholders and technical teams, ensuring alignment between business needs and technical solutions.",
      ],
    },
    {
      yearRange: "Jan 2019 - Feb 2022",
      title: "Founder and Owner",
      place: "Raymond Limited",
      desc: [
        "Worked with multiple teams, coordinated supply chain & communications, negotiated costs which estimated in around 10k CAD savings in inventory cost.",
        "Increased customer satisfaction by 10% in year one by directly communicating with consumers one on one.",
        "Optimized Pricing and grew profit margin to more than 60% for 6 products.",
        "Developed retail promotion & advertising plans (print media, digital, blogs etc) to drive sales by 10x and bring value to business especially during Covid",
        "Hired a team of 25 employees over a span of 7 months."
      ],
    },
    {
      yearRange: "Jul 2016 - Dec 2018",
      title: "Analyst",
      place: "BNY Mellon",
      desc: [
        "Streamline and automate workflows using Process Builder, Flow, and Apex triggers, resulting in a 25% increase in efficiency.",
        "Assisted senior Salesforce administrators in creating and maintaining reports, dashboards, and analytics.",
        "Successfully migrated legacy data into Salesforce using Data Loader, ensuring a smooth transition and continuity of historical records.",
        "Created and maintained dashboard/website for data visualization for hedge fund managers using JS, AngularJS and C#."
      ],
    }
  ];

  const educationDetails = [
    {
      yearRange: "Aug 2012 - Jul 2016",
      title: "Bachelor's degree, Computer Software Engineering",
      place: "Pune Institute of Computer Technology",
      desc: "Completed my Bachelor's in Computer Science with distinction, showcasing dedication, expertise, and a passion for excellence in every endeavor.",
    }
  ];

  const skills = [
    {
      name: "Data Analyst",
      percent: 85,
    },
    {
      name: "Business Analyst",
      percent: 95,
    },
    {
      name: "Python",
      percent: 80,
    },
    {
      name: "Digital Marketing",
      percent: 85,
    },
    {
      name: "Salesforce Applications",
      percent: 90,
    },
    {
      name: "C#",
      percent: 85,
    },
    {
      name: "Angular",
      percent: 80,
    },
    {
      name: "Graphic Design",
      percent: 90,
    }
  ];

  return (
    <section
      id="resume"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Summary
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Resume
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gx-5">
          {/* My Experience */}
          <div className="col-md-12">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Experience
            </h2>
            <div className="row">
              {experienceDetails.map((value, index) => (
                <div key={index} className="col-md-6">
                  <div
                    className={
                      "bg-white  rounded p-4 mb-4 " +
                      (darkTheme ? "bg-dark" : "bg-white border")
                    }
                  >
                    <p className="badge bg-primary text-2 fw-400">
                      {value.yearRange}
                    </p>
                    <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                      {value.title}
                    </h3>
                    <p className={darkTheme ? "text-primary" : "text-danger"}>
                      {value.place}
                    </p>
                    {Array.isArray(value.desc) ? (
                      <ul>
                        {value.desc.map((point, index) => (
                          <li key={index}>{point}</li>
                        ))}
                      </ul>
                    ) : (
                      <p
                        className={"mb-0 " + (darkTheme ? "text-white-50" : "")}
                      >
                        {value.desc}
                      </p>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* My Education */}
        <div className="row gx-5">
          <div className="col-md-12">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Education
            </h2>
            {educationDetails.length > 0 &&
              educationDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                    {value.place}
                  </p>
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {value.desc}
                  </p>
                </div>
              ))}
          </div>
        </div>
        {/* My Skills */}
        <h2
          className={
            "text-6 fw-600 mt-4 mb-4 " + (darkTheme ? "text-white" : "")
          }
        >
          My Skills
        </h2>
        <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <div key={index} className="col-md-6">
                <p
                  className={
                    " fw-500 text-start mb-2 " +
                    (darkTheme ? "text-light" : "text-dark")
                  }
                >
                  {skill.name}{" "}
                  <span className="float-end">{skill.percent}%</span>
                </p>
                <div
                  className={
                    "progress progress-sm mb-4 " + (darkTheme ? "bg-dark" : "")
                  }
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>
        <div className="text-center mt-5">
          <a
            className="btn btn-outline-secondary rounded-pill shadow-none"
            href="http://216.48.185.242/ShriyamJalanResumeBA.pdf"
            target="_blank"
            download
          >
            Resume
            <span className="ms-1">
              <i className="fas fa-download" />
            </span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Resume;
