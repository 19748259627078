import React from "react";
import Typewriter from "typewriter-effect";
import { Tooltip } from "./Tooltip";

const Home = ({ classicHeader, darkTheme, handleNavClick }) => {
  return (
    <section id="home">
      <div className="hero-wrap">
        <div className="hero-mask opacity-8 bg-dark" />
        <div
          className="hero-bg parallax"
          style={{ backgroundImage: 'url("images/intro-bg.jpg")' }}
        ></div>
        <div className="hero-content section d-flex min-vh-100">
          <div className="container my-auto">
            <div className="row">
              <div className="col-12 text-center">
                <p className="text-7 fw-500 text-white mb-2 mb-md-3">Welcome</p>
                <h2 className="text-16 fw-600 text-white mb-2 mb-md-3">
                  <Typewriter
                    options={{
                      strings: ["I'm Shriyam Jalan.", "I'm a Business Analyst."],
                      autoStart: true,
                      loop: true,
                    }}
                  />
                </h2>

                <div className="d-flex justify-content-center align-items-center mb-3">
                  <a
                    href="#contact"
                    className="btn btn-outline-primary rounded-pill shadow-none smooth-scroll mr-2"
                    onClick={(e) => {
                      e.preventDefault();
                      handleNavClick("contact");
                    }}
                  >
                    
                    Contact Me
                  </a>
                  <Tooltip text="Calendly" placement="top">
                    <a
                      href="https://calendly.com/jalanshriyam/30min"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa-solid fa-calendar-days fa-lg" style={{ marginLeft: '8px' }} />
                    </a>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
          <a
            href="#about"
            className="scroll-down-arrow text-white smooth-scroll"
            onClick={(e) => {
              e.preventDefault();
              handleNavClick("about");
            }}
          >
            <span className="animated">
              <i className="fa fa-chevron-down" />
            </span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Home;
